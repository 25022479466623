import Home from "./components/Home";
import Registration from "./components/user/Registration";
import Login from "./components/user/Login";
import AccountManagment from "./components/user/AccountManagement";
import ResetPassword from "./components/user/ResetPassword";
import NewPassword from "./components/user/NewPassword";
import Statistics from "./components/admin/Statistics";
import Message from "./components/Messages/Message";
import PriceMap from "./components/PriceMap";
import GlobalStatistics from "./components/GlobalStatistics";
import AboutUs from "./components/AboutUs";
import Contact from "./components/Contact";
import Privacy from "./components/Privacy";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useState, useEffect } from "react";
import AkcenLogo from "./Images/logo/akcen_logo_no_text.png";

function App() {
  const [isParam, setParam] = useState(false);
  const [isLogged, setIsLogged] = useState(false);
  const [infoUser, setInfoUser] = useState({
    email: "",
    refreshToken: "",
    photo: null,
    name: "",
    surname: "",
    phone1: " ",
    phone2: " ",
    companyName: "",
    ico: "",
    dic: "",
    icDph: "",
    street: "",
    number: "",
    city: "",
    postalCode: "",
    country: "",
    latitude: "",
    longitude: "",
  });

  useEffect(() => {
    if (localStorage.getItem("userData") === null) {
      setIsLogged(false);
    } else {
      setIsLogged(true);
      const userData = JSON.parse(localStorage.getItem("userData"));
      // console.log("userData pic = ", userData.p);
      setInfoUser({
        ...infoUser,
        email: userData.m !== null ? userData.m : "",
        photo: userData.p !== null ? userData.p : localStorage.getItem("p"),
        name: userData.n !== null ? userData.n : "",
        surname: userData.sn !== null ? userData.sn : "",
        phone1: userData.ph1 !== null ? userData.ph1 : "",
        phone2: userData.ph2 !== null ? userData.ph2 : "",
      });
    }
    if (window.location.pathname.length > 1) {
      setParam(true);
      return;
    }
    setParam(false);
    // eslint-disable-next-line
  }, []);

  const getPath = () => {
    if (isParam) {
      const path = "/:value/:value/:value/:value";
      return path;
    }
    const path = "/";
    return path;
  };

  // return (
  //   <Router>
  //     <Routes>
  //       <Route
  //         exact
  //         path={getPath()}
  //         element={<Home isLogged={isLogged} setIsLogged={setIsLogged} infoUser={infoUser} />}
  //       />
  //       <Route
  //         exact
  //         path={"/add"}
  //         element={<Home isLogged={isLogged} setIsLogged={setIsLogged} infoUser={infoUser} />}
  //       />
  //       <Route exact path={"/registracia"} element={<Registration />} />
  //       <Route
  //         exact
  //         path={"/prihlasenie"}
  //         element={<Login setIsLogged={setIsLogged} isParam={isParam} setInfoUser={setInfoUser} infoUser={infoUser} />}
  //       />
  //       <Route path="/obnova-hesla" element={<ResetPassword isParam={isParam} />} />
  //       <Route path="/obnova-hesla/:token" element={<NewPassword isParam={isParam} />} />
  //       {isLogged ? (
  //         <>
  //           <Route
  //             path="/sprava-uctu/profil"
  //             element={<AccountManagment infoUser={infoUser} setInfoUser={setInfoUser} />}
  //           />
  //           <Route
  //             path="/sprava-uctu/moje-nehnutelnosti"
  //             element={<Statistics infoUser={infoUser} setInfoUser={setInfoUser} />}
  //           />
  //         </>
  //       ) : null}
  //       {/* <Route path="*" element={<SomethingWentWrong isParam={isParam} />} /> */}
  //       <Route path="/spravy" element={<Message />} />
  //       <Route path="/cenova-mapa" element={<PriceMap />} />
  //       <Route path="/statistiky" element={<GlobalStatistics />} />
  //       <Route path="/o-nas" element={<AboutUs />} />
  //       <Route path="/kontakt" element={<Contact />} />
  //       <Route path="/sukromie" element={<Privacy />} />
  //     </Routes>
  //   </Router>
  // );
  return (
    <div className="bg-sky-100 w-full h-screen flex flex-col justify-center items-center gap-2 p-5">
      <div className="text-3xl text-center">Najlepší vyhľadávač nehnuteľností na Slovensku už čoskoro k dispozícii</div>
      <img src={AkcenLogo} alt="" className="w-40 h-40" />
    </div>
  );
}

export default App;
