import { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faHouse, faBuilding, faX, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { useMediaQuery } from "usehooks-ts";
import clsx from "clsx";
import { motion, AnimatePresence } from "framer-motion";
import Rating from "../NewMenu.jsx/Rating";
import Share from "../NewMenu.jsx/Share";
import Interest from "../NewMenu.jsx/Interest";
import LV from "../NewMenu.jsx/LV";
import Owner from "../NewMenu.jsx/Owner";

library.add(faHouse);
library.add(faBuilding);
library.add(faX);

export default function HistoryList({
  historyItems,
  extended,
  map,
  setCurrentIndex,
  setVisibilityOfMarkers,
  lvSubMenu,
  setLvSubMenu,
  setSubMenu,
  subMenu,
  setInputValues,
  inputValues,
  rating,
  setRating,
  ownerMenu,
  setOwnerMenu,
  setExpanded,
  isExpanded,
  indexCur,
}) {
  const druhStavby = useRef("");
  const historyItem = useRef("");
  const indx = useRef(indexCur);
  const [previous, setPrevious] = useState("");

  const isSmallDevice = useMediaQuery("only screen and (max-width : 640px)");
  // console.log("historyItems = ", historyItems);

  useEffect(() => {
    // console.log("reload", indexCur.current);
    if (isSmallDevice) {
      if (indexCur.current !== null) {
        indx.current.scrollIntoView({ behavior: "smooth", inline: "start", block: "start" });
      }
    }
  });

  function icon(index) {
    if (historyItems[index].response?.List?.length !== undefined) {
      historyItem.current = historyItems[index].response?.List[0];
      if (historyItems[index].response?.List[0]?.table_stavby?.length === undefined) {
        druhStavby.current = historyItems[index].response?.List[0]?.table_stavby?.druh_stavby;
      } else {
        druhStavby.current = historyItems[index].response?.List[0]?.table_stavby[0]?.druh_stavby;
      }
    } else {
      historyItem.current = historyItems[index].response?.List;
      // console.log("druh stavby = ", historyItems[index].response?.List?.table_stavby?.length);
      if (historyItems[index].response?.List?.table_stavby?.length === undefined) {
        druhStavby.current = historyItems[index].response?.List?.table_stavby?.druh_stavby;
      } else {
        druhStavby.current = historyItems[index].response?.List?.table_stavby[0]?.druh_stavby;
      }
    }

    if (historyItem.current?.hasOwnProperty("table_stavby")) {
      if (druhStavby.current === "9") {
        return <FontAwesomeIcon icon="building" className="text-xl w-8" />;
      } else {
        return <FontAwesomeIcon icon="house" className="text-xl w-8" />;
      }
    } else {
      return <FontAwesomeIcon icon="x" className="text-xl w-8" />;
    }
  }

  const showMenu = (menu) => {
    if (subMenu.lv) setPrevious("lv");
    if (subMenu.interest) setPrevious("in");
    if (subMenu.owner) setPrevious("ow");
    if (subMenu.rating) setPrevious("ra");
    if (subMenu.share) setPrevious("sh");

    if (menu === "lv") {
      setSubMenu({ ...subMenu, lv: true, interest: false, owner: false, rating: false, share: false });
    }
    if (menu === "in") {
      setSubMenu({ ...subMenu, lv: false, interest: true, owner: false, rating: false, share: false });
    }
    if (menu === "ow") {
      setSubMenu({ ...subMenu, lv: false, interest: false, owner: true, rating: false, share: false });
    }
    if (menu === "ra") {
      setSubMenu({ ...subMenu, lv: false, interest: false, owner: false, rating: true, share: false });
    }
    if (menu === "sh") {
      setSubMenu({ ...subMenu, lv: false, interest: false, owner: false, rating: false, share: true });
    }
  };

  const expand = (index) => {
    if (isSmallDevice) {
      if (isExpanded === index) {
        setExpanded(-1);
        indexCur.current = null;
        return;
      }
      setExpanded(index);
    }
  };

  return (
    <>
      {historyItems.map((historyItem, index) => (
        <div
          key={index}
          className={clsx(
            "transition-all duration-500 ease-linear min-h-max flex-col justify-center sm:flex overflow-ellipsis",
            {
              hidden: !extended,
              "w-full ": isSmallDevice,
            },
          )}
        >
          <div
            ref={isSmallDevice ? (indexCur.current === index ? indx : null) : null}
            onClick={() => {
              map.panTo({ lat: historyItem.latAndLng.lat, lng: historyItem.latAndLng.lng });
              setCurrentIndex(index);
              setVisibilityOfMarkers("singleHistoryPin");
              icon(index);
              expand(index);
            }}
            className={clsx("px-3 py-2 w-full flex flex-col text-xs leading-snug text-black hover:opacity-75 gap-1", {
              "bg-white rounded-3xl": extended,
              "rounded-none !bg-transparent border-b-2 border-b-gray-300": isSmallDevice,
              "items-center justify-center": !extended,
            })}
          >
            <div
              className={clsx("", {
                "flex flex-row w-full gap-x-2 items-center": extended,
              })}
            >
              {icon(index)}
              <div
                className={clsx("w-full flex justify-between", {
                  "pr-10": isSmallDevice,
                })}
              >
                <div className="flex flex-col">
                  <div className="flex flex-row gap-x-2">
                    <p className="max-h-4 overflow-hidden">
                      {historyItem.location} {historyItem.number}
                    </p>
                  </div>
                  <p>Priemerná cena: {historyItem.avgPrice?.avgPrice} €</p>
                </div>
                <div
                  onClick={() => {
                    window.open(
                      `https://kataster.skgeodesy.sk/Portal45/api/Bo/GeneratePrfPublic?prfNumber=${historyItem?.response?.List?.cislo_listu_vlastnictva}&cadastralUnitCode=${historyItem?.response?.List?.nazov_katastralneho_uzemia}&outputType=pdf`,
                      "_blank",
                    );
                  }}
                  className="w-10 h-8 flex justify-center items-center cursor-pointer"
                >
                  <FontAwesomeIcon icon={faFilePdf} className="w-5 h-5" />
                </div>
              </div>
            </div>
            {isExpanded === index && isSmallDevice ? (
              <AnimatePresence>
                <motion.div
                  className="w-full h-auto flex flex-col bg-sky-100 px-2 py-1"
                  initial={{ opacity: 0, scale: 1, height: 0 }}
                  animate={{ opacity: 1, scale: 1, height: "auto" }}
                  exit={{ opacity: 1, scale: 1, height: 0 }}
                  transition={{
                    duration: 0.3,
                    ease: [0, 0.71, 0.2, 1.01],
                  }}
                >
                  <motion.div className="w-auto flex flex-col items-start overflow-x-scroll no-scrollbar">
                    <div className="text-base w-auto flex items-center font-semibold flex-row">
                      <button
                        onClick={(e) => {
                          showMenu("lv");
                          e.stopPropagation();
                        }}
                        className={clsx("w-32 py-1 border-b-2 border-gray-400", {
                          "border-b-2 !border-blue-700 text-blue-700": subMenu?.lv,
                        })}
                      >
                        List Vlastnictva
                      </button>
                      <button
                        onClick={(e) => {
                          showMenu("in");
                          e.stopPropagation();
                        }}
                        className={clsx("w-32 py-1 border-b-2 border-gray-400", {
                          "border-b-2 !border-blue-700 text-blue-700": subMenu?.interest,
                        })}
                      >
                        Mám záujem
                      </button>
                      <button
                        onClick={(e) => {
                          showMenu("ow");
                          e.stopPropagation();
                        }}
                        className={clsx("w-32 py-1 border-b-2 border-gray-400", {
                          "border-b-2 !border-blue-700 text-blue-700": subMenu?.owner,
                        })}
                      >
                        Som vlastník
                      </button>
                      <button
                        onClick={(e) => {
                          showMenu("ra");
                          e.stopPropagation();
                        }}
                        className={clsx("w-24 py-1 border-b-2 border-gray-400", {
                          "border-b-2 !border-blue-700 text-blue-700": subMenu?.rating,
                        })}
                      >
                        Hodnotiť
                      </button>
                      <button
                        onClick={(e) => {
                          showMenu("sh");
                          e.stopPropagation();
                        }}
                        className={clsx("w-24 py-1 border-b-2 border-gray-400", {
                          "border-b-2 !border-blue-700 text-blue-700": subMenu?.share,
                        })}
                      >
                        Zdielať
                      </button>
                    </div>
                  </motion.div>
                  {/* LV */}
                  <LV
                    subMenu={subMenu}
                    historyItem={historyItem}
                    lvSubMenu={lvSubMenu}
                    setLvSubMenu={setLvSubMenu}
                    previous={previous}
                  />
                  {/* LV */}
                  {/* Interest */}
                  <Interest
                    subMenu={subMenu}
                    inputValues={inputValues}
                    setInputValues={setInputValues}
                    previous={previous}
                  />
                  {/* Interest */}
                  {/* Owner */}
                  <Owner
                    subMenu={subMenu}
                    ownerMenu={ownerMenu}
                    setOwnerMenu={setOwnerMenu}
                    inputValues={inputValues}
                    setInputValues={setInputValues}
                    previous={previous}
                  />
                  {/* Owner */}
                  {/* Rating */}
                  <Rating
                    subMenu={subMenu}
                    rating={rating}
                    setRating={setRating}
                    inputValues={inputValues}
                    setInputValues={setInputValues}
                    previous={previous}
                  />
                  {/* Rating */}
                  {/* Share */}
                  <Share subMenu={subMenu} />
                  {/* Share */}
                </motion.div>
              </AnimatePresence>
            ) : null}
          </div>
        </div>
      ))}
    </>
  );
}
